<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Remote Video Zone</strong>
            </div>
            <div class="text-muted">Create new Remote Video Zone</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Advertiser">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Advertiser of the Remote Video Zone.</span>
                  </template>
                  <a-select
                    v-decorator="['advertiser', { initialValue:initialValue, rules: [{ required: true, message: 'Please select advertiser.' }]}]"
                    placeholder="Please select an advertiser"
                    :showSearch="true"
                    :filterOption="true"
                    optionFilterProp="children"
                  >
                    <a-select-option v-for="obj in advertiser.list.filter(ele => ele.Status === 0 )" :key="obj.Id">
                      [{{obj.Id}}] {{ obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Remote Video Zone's name.</span>
                  </template>
                  <a-input type="text" placeholder="Remote Video Zone Name" v-decorator="[ 'name', {
                  rules: [
                  { required: true, message: 'Name is required.' },
                  { max: 64, message: 'Maximum 64 characters allowed.'}
                  ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of bids per day. The system will even out distribution of bids throughout the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                  </template>
                  <a-input type="number" min="0" placeholder="Bids Daily Limit"
                           v-decorator="[ 'bidsDailyLimit', { initialValue: 0 }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                  </template>
                  <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                            v-decorator="[ 'clicksDailyLimit', { initialValue: 0 }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zone URL">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Remote Video Zone's URL.</span>
                  </template>
                  <a-input type="url" placeholder="Zone URL" v-decorator="['zoneUrl', {
                    rules: [
                    { required: true, message: 'Zone URL is required.' },
                    { max: 512, message: 'Maximum 512 characters allowed.'}
                    ]}]">
                    <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                      <template slot="title">
                        <b>{cc}</b> - Lowercase country code will be replaced.<br>
                        <b>{CC}</b> - Uppercase country code will be replaced.<br>
                        <b>{Country}</b> - Capitalized country name will be replaced.<br>
                        <b>{ip}</b> - IP will be replaced.<br>
                        <b>{ua}</b> - User-agent string will be replaced.<br>
                        <b>{width}</b> - Width will be replaced.<br>
                        <b>{height}</b> - Height will be replaced.<br>
                        <b>{linearity}</b> - Linearity will be replaced.<br>
                        <b>{site_url}</b> - Site URL will be replaced.<br>
                        <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                        <b>{app_name}</b> - App Name will be replaced.<br>
                        <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                        <b>{bidfloor}</b> - Minimal CPM for the request.<br>
                        <b>{minduration}</b> - Minimal Video Ad duration in seconds.<br>
                        <b>{maxduration}</b> - Maximal Video Ad duration in seconds.<br>
                        <b>{category}</b> - Advertising category according to IAB classification.<br>
                        <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                        <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                        <b>{schain}</b> - Serialized schain parameter.<br>
                        <b>{lat}</b> - Latitude will be replaced.<br>
                        <b>{lon}</b> - Longitude will be replaced.<br>
                        <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                        <b>{cid}</b> - Click ID will be replaced.<br>
                      </template>
                      <a-icon type="code"/>
                    </a-tooltip>
                  </a-input>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Remote Video Zone's description.</span>
                  </template>
                  <a-input placeholder="Remote Video Zone Description" v-decorator="['description', {
                  rules: [
                      { max: 512, message: 'Maximum 512 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Remote Video Zone</button>
                  <button type="button" @click="$router.push({ name: 'display-remotevideozones' })"
                          class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createRemoteVideoZone } from '@/api/display/remotevideozone'

export default {
  components: {},
  computed: {
    ...mapState(['advertiser']),
    // initialValue() {
    //   if (this.$route.params.advertiserId) {
    //     return this.advertiser.list.filter(ele => ele.Id === parseInt(this.$route.params.advertiserId))[0].Id
    //   } else {
    //     return []
    //   }
    // },
    initialValue() {
      if (this.$route.params.advertiserId) {
        const advertiser = this.advertiser.list.find(ele => ele.Id === parseInt(this.$route.params.advertiserId))
        return advertiser === undefined ? [] : advertiser.Id
      } else {
        return []
      }
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  watch: {
    checkedKeys(val) {
      // console.log('onCheck', val)
    },
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.zoneUrl !== '' && values.zoneUrl) {
            values.zoneUrl = values.zoneUrl.replace(/ /g, '')
          }
          createRemoteVideoZone(values).then(response => {
            if (response.Status === 'Ok') {
              this.$notification.success({
                message: 'Remote Video Zone Created',
                description: `Remote Video Zone ${response.Remotevideozone.Name} has been successfully created.`,
              })
              // this.$router.push('/display/remotevideozone/update/' + response.Remotevideozone.Id)
              this.$router.push({
                name: 'display-remotevideozone-update',
                params: { id: response.Remotevideozone.Id },
              }).catch(() => {
              })
            } else if (typeof response.type !== 'undefined' && response.type === 'error') {
              this.$notification.success({
                message: 'Remote Video Zone Created',
                description: 'Remote Video Zone has been successfully created.',
              })
            }
          }).catch(err => console.log(err))
          // this.$store.dispatch('remotevideozone/CREATE_ZONE', { payload: values, callback: function() { this.$router.push('/remotevideozones') }.bind(this) })
        }
      })
    },
  },
  created() {
    this.$store.dispatch('advertiser/LOAD_ADVERTISERS_SHORT')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
